import React from 'react';
import { Box, Link } from '@mui/material';
import { CvtColorSchema, CvtBasicTheme } from '../../../../global/cvt-obj';

export type TopicCardProps = {
  /**
   * a headline to be rendered as the card's topic.
   */
  headline: string;
  /**
   * a text to be rendered in the headline.
   */
  text: string;
  /**
   * the buttons text color.
   */
  color: string;
  /**
   * the button's background color.
   */
  bgColor: string;
  /**
   * Specifies the color of the linear-gradient-hover-effect.
   */
  hoverColor: string;
  /**
   * Specifies the link path to which the card should redirect.
   */
  linkPath?: string;
  /**
   * Specifies the cards width.
   */
  maxWidth: number;
};

export const TopicCard: React.FC<TopicCardProps> = ({
  headline,
  text,
  color = CvtColorSchema.white,
  bgColor = CvtColorSchema.green,
  hoverColor = CvtColorSchema.yellow,
  linkPath,
  maxWidth = 300,
}: TopicCardProps) => {
  const styles = {
    container: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: `${maxWidth}px`,
        maxWidth: `${maxWidth}px`,
      },
      topic: {
        skew: {
          color: color,
          backgroundColor: bgColor,
          padding: `36px ${maxWidth / 2}px`,
          fontSize: CvtBasicTheme.typography.fontSize.large,
          transform: CvtBasicTheme.transform.skew,

          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `linear-gradient(${bgColor}, ${bgColor})`,
          backgroundSize: '0% 100%',
          transition: `background-size 0.5s, background-image 0.5s`,

          '&:hover': {
            backgroundImage: `linear-gradient(${hoverColor}, ${hoverColor})`,
            backgroundSize: '100% 100%',
            cursor: 'pointer',
          },
        },
        unskew: {
          width: `${maxWidth}px`,
          maxWidth: `${maxWidth}px`,
          textAlign: 'center',
          transform: CvtBasicTheme.transform.unskew,
        },
      },
      text: {
        display: 'flex',
        marginTop: '2em',
        width: `${maxWidth}px`,
        maxWidth: `${maxWidth}px`,
      },
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      fontWeight: 'normal',
    },
  } as const;

  return (
    <Box component={'div'} sx={styles.container.root}>
      <Link href={linkPath} sx={styles.link}>
        <Box component={'div'} sx={styles.container.topic.skew}>
          <Box component={'div'} sx={styles.container.topic.unskew}>
            {headline}
          </Box>
        </Box>
      </Link>
      <Box component={'div'} sx={styles.container.text}>
        {text}
      </Box>
    </Box>
  );
};
